/* Spacing */
$spacings: 0 0.25rem 0.5rem 0.75rem 1rem 1.25rem 1.5rem 1.75rem 2rem 2.5rem 3rem
  4rem 6rem 8rem 12rem 16rem 24rem 32rem 40rem 48rem;
$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 0.75rem; // 12px
$spacing-4: 1rem; // 16px
$spacing-5: 1.25rem; // 20px
$spacing-6: 1.5rem; // 24px
$spacing-7: 1.75rem; // 28px
$spacing-8: 2rem; // 32px
$spacing-9: 2.5rem; // 40px
$spacing-10: 3rem; // 48px
$spacing-11: 4rem; // 64px
$spacing-12: 6rem; // 96px
$spacing-13: 8rem; // 128px
$spacing-14: 12rem; // 192px
$spacing-15: 16rem; // 256px
$spacing-16: 24rem; // 384px
$spacing-17: 32rem; // 512px
$spacing-18: 40rem; // 640px
$spacing-19: 48rem; // 768px

/* Functional */
$color-success: rgba(93, 202, 150, 100);
$color-warning: rgba(242, 197, 83, 100);
$color-error: rgba(203, 67, 67, 100);
$color-information: rgba(27, 35, 40, 90);
$color-light-gray: rgba(102, 102, 102, 0.8);
$color-primary: #316efd;
$color-left-menu: #b1b1b1;

/* FONTS */
$font-family: 'Inter', sans-serif;
$font-family-body: 'Inter', sans-serif;
$font-family-header: 'Inter', sans-serif;
$font-family-sans: $font-family;

$primary-font-size: 14px;
$primary-font-size-value: 14;

$font-weight-normal: 500;
