@import './variables';

:root {
  --app-height: 100%;
}

:global {
  html {
    font-size: 16px;
  }

  body {
    font-size: 14px;
  }
}

@mixin font-size($size) {
  font-size: calc($size/$primary-font-size-value) + rem;
}

@mixin width($width) {
  width: $width / $primary-font-size-value + rem;
}

@mixin height($height) {
  height: $height / $primary-font-size-value + rem;
}

@mixin min-width($width) {
  min-width: $width / $primary-font-size-value + rem;
}

@mixin min-height($height) {
  min-height: $height / $primary-font-size-value + rem;
}

@mixin max-width($width) {
  max-width: $width / $primary-font-size-value + rem;
}

@mixin max-height($height) {
  max-height: $height / $primary-font-size-value + rem;
}

@mixin margin($margin, $direction: all) {
  @if $direction == all {
    margin: $margin / $primary-font-size-value + rem;
  } @else if ($direction == top) {
    margin-top: $margin / $primary-font-size-value + rem;
  } @else if ($direction == bottom) {
    margin-bottom: $margin / $primary-font-size-value + rem;
  } @else if ($direction == left) {
    margin-left: $margin / $primary-font-size-value + rem;
  } @else if ($direction == right) {
    margin-right: $margin / $primary-font-size-value + rem;
  } @else if ($direction == leftRight) {
    margin-left: $margin / $primary-font-size-value + rem;
    margin-right: $margin / $primary-font-size-value + rem;
  } @else if ($direction == topBottom) {
    margin-top: $margin / $primary-font-size-value + rem;
    margin-bottom: $margin / $primary-font-size-value + rem;
  }
}

@mixin padding($padding, $direction: all) {
  @if $direction == all {
    padding: $padding / $primary-font-size-value + rem;
  } @else if ($direction == top) {
    padding-top: $padding / $primary-font-size-value + rem;
  } @else if ($direction == bottom) {
    padding-bottom: $padding / $primary-font-size-value + rem;
  } @else if ($direction == left) {
    padding-left: $padding / $primary-font-size-value + rem;
  } @else if ($direction == right) {
    padding-right: $padding / $primary-font-size-value + rem;
  } @else if ($direction == leftRight) {
    padding-left: $padding / $primary-font-size-value + rem;
    padding-right: $padding / $primary-font-size-value + rem;
  } @else if ($direction == topBottom) {
    padding-top: $padding / $primary-font-size-value + rem;
    padding-bottom: $padding / $primary-font-size-value + rem;
  }
}
