@import 'shared/assets/styles/variables.scss';

input,
textarea,
select {
  font-size: 16px !important;
  /* Prevent zoom on input fields for ios for preventing default behaviour of zoom from safari */
}

.sidebar {
  .nav-text {
    color: $color-left-menu;
  }
  .nav-item > a:hover {
    background-color: transparent !important;
  }

  .OpenLogo {
    width: 180px;
  }

  .group.active {
    background-color: transparent !important;
    .nav-text {
      color: $color-primary;
    }

    .active-border {
      background: $color-primary;
      border-radius: 0 4px 4px 0;
      width: 4px;
      position: absolute;
      left: 0;
    }
  }
}
.main-content-sidebar {
  width: calc(100vw - 260px);

  @media (max-width: 1024px) {
    width: 100vw;
  }
}

.headlessui-portal-root {
  position: absolute;
  z-index: 100;
}
#react-doc-viewer{
 overflow-y: auto;
}
#msdoc-iframe {
  height: 80vh;
}

#header-bar {
  display: none;
}
#pdf-controls {
  display: none;
}

#doc-viewer {
  height: 80vh;
}

iframe {
  height: 80vh;
}
